// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: { [key: string]: any } & {
  production: boolean;
  maintainerAircraftAdmin_role: string;
  maintainerAircraftUser_role: string;
  azure_client_id: string;
  msal: {
    auth: {
      clientId: string;
      authority: string;
      redirectUri: string;
    };
    cache: {
      cacheLocation: string;
      storeAuthStateInCookie: boolean;
    };
  };
  guard: {
    interactionType: string;
    authRequest: {
      scopes: string[];
      prompt: string;
    };
  };
  interceptor: {
    interactionType: string;
    protectedResourceMap: [string, string[]][];
  };
} = {
  production: false,
  maintainerAircraftAdmin_role: "ITINMANTTO_ADMIN_DEV",
  maintainerAircraftUser_role: "ITINMANTTO_USER_DEV",
  azure_client_id: "51850476-b7cf-4eba-b93a-d03dfe8638c0",
  msal: {
    auth: {
      clientId: "51850476-b7cf-4eba-b93a-d03dfe8638c0",
      authority:
        "https://login.microsoftonline.com/99d911b9-6dc3-401c-9398-08fc6b377b74",
      redirectUri: "https://itinerario-mantto.dev.appslatam.com/mantenedor",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  },
  guard: {
    interactionType: "redirect",
    authRequest: {
      scopes: ["user.read", "directory.read.all"],
      prompt: "select_account",
    },
  },
  interceptor: {
    interactionType: "redirect",
    protectedResourceMap: [
      [
        "https://graph.microsoft.com/v1.0/me",
        ["user.read", "directory.read.all"],
      ],
    ],
  },
};

export const bff = {
  protocol: "https://",
  host: "itinerario-mantto.dev.appslatam.com",
  port: "",
  editAircraft: "/itin-compara-mant/maintainer-aircraft-bff/edit-aircraft",
  saveAircraft: "/itin-compara-mant/maintainer-aircraft-bff/save-aircraft",
  getAircraft: "/itin-compara-mant/maintainer-aircraft-bff/get-aircraft",
  emailValidation: "/itin-compara-mant/validateEmail/email",
  tokenValidation:
    "/itin-compara-mant/authentication/verifyGoogleSecurityToken",
};
