import { ENavMenu, IRoleConfig } from "./roles.interface";

export const ROLES_CONFIG: IRoleConfig[] = [
  {
    menu: ENavMenu.MAINTAINER,
    roles: [
      {
        role: "maintainerAircraftAdmin_role",
        basePath: "mantenedor",
        redirect: "mantenedor",
        menuName: "Mantenedor",
      },
      {
        role: "maintainerAircraftUser_role",
        basePath: "mantenedor",
        redirect: "mantenedor",
        menuName: "Mantenedor",
      },
    ],
  },
  {
    menu: ENavMenu.COMPARATOR,
    roles: [],
  },
];
